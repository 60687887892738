<!-- eslint-disable max-len -->
<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/decompte'">
          Factures >
        </router-link>
        <router-link :to="`/decompte/dgir/detail/${$route.params.id}`">
          Détail de la facture n°{{$route.params.id}} >
        </router-link>
        <span>Modification de facture</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Modification de facture
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="row">
        <Notif :notif="notif" />
      </div>
      <form class="accordion" 
            id="accordionExample">  
        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingOne">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
              <th>Source de la facture</th>
            </tr>
          </thead>
          <div id="collapseOne"
               class="collapse show"
               aria-labelledby="headingOne"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Numéro de la lettre de commande <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select :options="marches.donnees"
                            :reduce="marche => marche.id"
                            label="numero_marche"
                            ref="marche"
                            class="v-select"
                            @input="searchMarche(form.marche)"
                            v-model="form.marche"
                            placeholder="Choisir une lettre de commande">
                    <span slot="no-options"
                          @click="$refs.marche.open = false"
                          class="text-danger">
                      Aucune lettre de commande trouvé
                    </span>
                  </v-select>
                  <span v-if="form.errors().has('marche') && affiche.marche !== 'marche'"
                        v-text="form.errors().get('marche')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <template v-if="marche.activite">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Ordonnancement <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select :options="marche.ordonnancements"
                              :reduce="ordonnancement =>ordonnancement.id"
                              label="annee_ordonnancement"
                              ref="marche"
                              class="v-select"
                              @input="searchSituation(form.marche,form.ordonnancement)"
                              v-model="form.ordonnancement"
                              placeholder="Choisir un ordonnancement">
                      <span slot="no-options"
                            @click="$refs.marche.open = false"
                            class="text-danger">
                        Aucun ordonnancement trouvé
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Programme</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.activite.rubrique.programme.libelle }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Rubrique</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.activite.rubrique.libelle }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Activité</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.activite.libelle }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Type d'entretien</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.type_entretien }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Département</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <span v-for="(departement,dekey) in marche.departement"
                          :key="dekey">{{ departement.libelle }}</span> <br>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Objet des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.objet }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date d'approbation</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_approbation_marche }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Lot</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.lot }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Entreprise</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.entreprise.libelle }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence ordre de service de démarrage</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.reference_ordre_service_demarrage }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date ordre de service de demarrage</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_ordre_service_demarrage }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de début</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_debut }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de fin</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_fin }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Délai d'exécution (mois)</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.delai_execution_marche }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date démarrage effectif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_demarrage_effectif }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de fin des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_fin_travaux }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence ordre de service d'arrêt</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.reference_ordre_service_arret }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date ordre de service d'arrêt</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_ordre_service_arret }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence ordre de service de reprise des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.reference_ordre_service_reprise_travaux }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date ordre de service de reprise des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_ordre_service_reprise_travaux }}</div>
                </div>
              </template>
            </div>
          </div>
        </table>

        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingTwo">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo">
              <th>Informations de la facture</th>
            </tr>
          </thead>
          <div id="collapseTwo"
               class="collapse"
               aria-labelledby="headingTwo"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Situation financière du marché</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  {{ form.situation_financiere_marche.toLocaleString() }} FCFA
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Situation financière de l'ordonnancement</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  {{ form.situation_financiere_ordonnancement.toLocaleString() }} FCFA
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label class="req">Numéro facture</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('numero_decompte')"
                         v-on:change="verifierNumeroDecompte()"
                         v-model="form.numero_decompte" />
                  <span v-if="form.errors().has('numero_decompte') && affiche.numero_decompte !== 'numero_decompte'"
                        v-text="form.errors().get('numero_decompte')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label class="req">Montant Facture</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         @input="addSpace('montant_facture_decompte')"
                         class="form-control"
                         v-on:click="removeSpanError('montant_facture_decompte')"
                         v-model="montant_facture_decompte" />
                  <span v-if="form.errors().has('montant_facture_decompte') && affiche.montant_facture_decompte  !== 'montant_facture_decompte'"
                        v-text="form.errors().get('montant_facture_decompte')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label class="req">Date Facture</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_facture')"
                         v-model="form.date_facture" />
                  <span v-if="form.errors().has('date_facture') && affiche.date_facture !== 'date_facture'"
                        v-text="form.errors().get('date_facture')"
                        class="errorMsg"> </span>
                </div>
              </div>    
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date établissement courrier d'appel de fonds</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_courrier_appel_fonds')"
                         v-model="form.date_etablissement_courrier_appel_fonds" />
                  <span v-if="form.errors().has('date_etablissement_courrier_appel_fonds') && affiche.date_etablissement_courrier_appel_fonds !== 'date_etablissement_courrier_appel_fonds'"
                        v-text="form.errors().get('date_etablissement_courrier_appel_fonds')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence courrier d'appel de fonds
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_courrier_appel_fonds')"
                         v-model="form.reference_courrier_appel_fonds" />
                  <span v-if="form.errors().has('reference_courrier_appel_fonds') && affiche.reference_courrier_appel_fonds !== 'reference_courrier_appel_fonds'"
                        v-text="form.errors().get('reference_courrier_appel_fonds')"
                        class="errorMsg"> 
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Etablissement Courrier indication ligne supp. dépense</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_courrier_indication_ligne_supportant_depense')"
                         v-model="form.date_etablissement_courrier_indication_ligne_supportant_depense" />
                  <span v-if="form.errors().has('date_etablissement_courrier_indication_ligne_supportant_depense') && affiche.date_etablissement_courrier_indication_ligne_supportant_depense !== 'date_etablissement_courrier_indication_ligne_supportant_depense'"
                        v-text="form.errors().get('date_etablissement_courrier_indication_ligne_supportant_depense')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence Courrier d’indication ligne supp. dépense
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_courrier_indication_ligne_supportant_depense')"
                         v-model="form.reference_courrier_indication_ligne_supportant_depense" />
                  <span v-if="form.errors().has('reference_courrier_indication_ligne_supportant_depense') && affiche.reference_courrier_indication_ligne_supportant_depense !== 'reference_courrier_indication_ligne_supportant_depense'"
                        v-text="form.errors().get('reference_courrier_indication_ligne_supportant_depense')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence Courrier de demande de proposition de prix
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_courrier_demande_proposition_prix')"
                         v-model="form.reference_courrier_demande_proposition_prix" />
                  <span v-if="form.errors().has('reference_courrier_demande_proposition_prix') && affiche.reference_courrier_demande_proposition_prix !== 'reference_courrier_demande_proposition_prix'"
                        v-text="form.errors().get('reference_courrier_demande_proposition_prix')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date établissement courrier demande proposition de prix</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_courrier_demande_proposition_prix')"
                         v-model="form.date_etablissement_courrier_demande_proposition_prix" />
                  <span v-if="form.errors().has('date_etablissement_courrier_demande_proposition_prix') && affiche.date_etablissement_courrier_demande_proposition_prix !== 'date_etablissement_courrier_demande_proposition_prix'"
                        v-text="form.errors().get('date_etablissement_courrier_demande_proposition_prix')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date établissement courrier d'invitation  à une séance de négociation</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_courrier_invitation_seance_negociation')"
                         v-model="form.date_etablissement_courrier_invitation_seance_negociation" />
                  <span v-if="form.errors().has('date_etablissement_courrier_invitation_seance_negociation') && affiche.date_etablissement_courrier_invitation_seance_negociation !== 'date_etablissement_courrier_invitation_seance_negociation'"
                        v-text="form.errors().get('date_etablissement_courrier_invitation_seance_negociation')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence Courrier d’invitation à une séance de négociation 
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_courrier_invitation_seance_negociation')"
                         v-model="form.reference_courrier_invitation_seance_negociation" />
                  <span v-if="form.errors().has('reference_courrier_invitation_seance_negociation') && affiche.reference_courrier_invitation_seance_negociation !== 'reference_courrier_invitation_seance_negociation'"
                        v-text="form.errors().get('reference_courrier_invitation_seance_negociation')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement du courrier de confirmation de prix</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_courrier_confirmation_prix')"
                         v-model="form.date_etablissement_courrier_confirmation_prix" />
                  <span v-if="form.errors().has('date_etablissement_courrier_confirmation_prix') && affiche.date_etablissement_courrier_confirmation_prix !== 'date_etablissement_courrier_confirmation_prix'"
                        v-text="form.errors().get('date_etablissement_courrier_confirmation_prix')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence de courrier de confirmation de prix 
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_courrier_confirmation_prix')"
                         v-model="form.reference_courrier_confirmation_prix" />
                  <span v-if="form.errors().has('reference_courrier_confirmation_prix') && affiche.reference_courrier_confirmation_prix !== 'reference_courrier_confirmation_prix'"
                        v-text="form.errors().get('reference_courrier_confirmation_prix')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement du courrier de demande de cotation</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_courrier_demande_cotation')"
                         v-model="form.date_etablissement_courrier_demande_cotation" />
                  <span v-if="form.errors().has('date_etablissement_courrier_demande_cotation') && affiche.date_etablissement_courrier_demande_cotation !== 'date_etablissement_courrier_demande_cotation'"
                        v-text="form.errors().get('date_etablissement_courrier_demande_cotation')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence demande de cotation 
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_demande_cotation')"
                         v-model="form.reference_demande_cotation" />
                  <span v-if="form.errors().has('reference_demande_cotation') && affiche.reference_demande_cotation !== 'reference_demande_cotation'"
                        v-text="form.errors().get('reference_demande_cotation')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement du formulaire de selection</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_formulaire_selection')"
                         v-model="form.date_etablissement_formulaire_selection" />
                  <span v-if="form.errors().has('date_etablissement_formulaire_selection') && affiche.date_etablissement_formulaire_selection !== 'date_etablissement_formulaire_selection'"
                        v-text="form.errors().get('date_etablissement_formulaire_selection')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence formulaire de selection
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_formulaire_selection')"
                         v-model="form.reference_formulaire_selection" />
                  <span v-if="form.errors().has('reference_formulaire_selection') && affiche.reference_formulaire_selection !== 'reference_formulaire_selection'"
                        v-text="form.errors().get('reference_formulaire_selection')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement du certificat de service fait</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_certificat_service_fait')"
                         v-model="form.date_etablissement_certificat_service_fait" />
                  <span v-if="form.errors().has('date_etablissement_certificat_service_fait') && affiche.date_etablissement_certificat_service_fait !== 'date_etablissement_certificat_service_fait'"
                        v-text="form.errors().get('date_etablissement_certificat_service_fait')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence certificat de service fait
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_certificat_service_fait')"
                         v-model="form.reference_certificat_service_fait" />
                  <span v-if="form.errors().has('reference_certificat_service_fait') && affiche.reference_certificat_service_fait !== 'reference_certificat_service_fait'"
                        v-text="form.errors().get('reference_certificat_service_fait')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement du certificat pour paiement</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_certificat_pour_paiement')"
                         v-model="form.date_etablissement_certificat_pour_paiement" />
                  <span v-if="form.errors().has('date_etablissement_certificat_pour_paiement') && affiche.date_etablissement_certificat_pour_paiement !== 'date_etablissement_certificat_pour_paiement'"
                        v-text="form.errors().get('date_etablissement_certificat_pour_paiement')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence du certificat pour paiement
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_certificat_paiement')"
                         v-model="form.reference_certificat_paiement" />
                  <span v-if="form.errors().has('reference_certificat_paiement') && affiche.reference_certificat_paiement !== 'reference_certificat_paiement'"
                        v-text="form.errors().get('reference_certificat_paiement')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement du bon de livraison</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_bon_livraison')"
                         v-model="form.date_etablissement_bon_livraison" />
                  <span v-if="form.errors().has('date_etablissement_bon_livraison') && affiche.date_etablissement_bon_livraison !== 'date_etablissement_bon_livraison'"
                        v-text="form.errors().get('date_etablissement_bon_livraison')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement ordre mission</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_ordre_mission')"
                         v-model="form.date_etablissement_ordre_mission" />
                  <span v-if="form.errors().has('date_etablissement_ordre_mission') && affiche.date_etablissement_ordre_mission !== 'date_etablissement_ordre_mission'"
                        v-text="form.errors().get('date_etablissement_ordre_mission')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence ordre de mission
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_ordre_mission')"
                         v-model="form.reference_ordre_mission" />
                  <span v-if="form.errors().has('reference_ordre_mission') && affiche.reference_ordre_mission !== 'reference_ordre_mission'"
                        v-text="form.errors().get('reference_ordre_mission')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date établissement lettre d'invitation formation</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input  type="date"
                          class="form-control"
                          v-on:click="removeSpanError('date_etablissement_lettre_invitation_formation')"
                          v-model="form.date_etablissement_lettre_invitation_formation" />
                  <span v-if="form.errors().has('date_etablissement_lettre_invitation_formation') && affiche.date_etablissement_lettre_invitation_formation !== 'date_etablissement_lettre_invitation_formation'"
                        v-text="form.errors().get('date_etablissement_lettre_invitation_formation')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Référence lettre d'invitation
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_lettre_invitation')"
                         v-model="form.reference_lettre_invitation" />
                  <span v-if="form.errors().has('reference_lettre_invitation') && affiche.reference_lettre_invitation !== 'reference_lettre_invitation'"
                        v-text="form.errors().get('reference_lettre_invitation')"
                        class="errorMsg">
                  </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date d'établissement du cautionnement AD</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date" 
                         class="form-control"
                         v-model="form.date_etablissement_cautionnement_ad"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Expiration de la caution AD</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="[
                      {value:'date',libelle:'Date'},
                      {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                      {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                      {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                    ]"
                    :reduce="expiration => expiration.value"
                    label="libelle"
                    ref="expiration_ad"
                    id="expiration_ad"
                    v-model="expiration_ad"
                    @input="checkExpiration('expiration_caution_ad_texte','expiration_ad'),form.expiration_caution_ad_texte=expiration_ad"
                    placeholder="Choisir un type d'expiration"
                  >
                    <span slot="no-options"
                          @click="$refs.programme.open = false"
                          class="text-danger">
                      Aucune type
                    </span>
                  </v-select>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-if="expiration_ad=='date'"
                         v-model="form.expiration_caution_ad_date" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement du cautionnement RG</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-model="form.date_etablissement_cautionnement_rg" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Expiration de la caution RG</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select :options="[
                              {value:'date',libelle:'Date'},
                              {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                              {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                              {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                            ]"
                            :reduce="expiration => expiration.value"
                            label="libelle"
                            ref="expiration_rg"
                            id="expiration_rg"
                            v-model="expiration_rg"
                            @change="checkExpiration('expiration_caution_rg_texte','expiration_rg')"
                            placeholder="Choisir un type d'expiration"
                  >
                    <span slot="no-options"
                          @click="$refs.programme.open = false"
                          class="text-danger">
                      Aucune type
                    </span>
                  </v-select>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-if="expiration_rg=='date'"
                         v-model="form.expiration_caution_rg_date" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date d'établissement du cautionnement définitif</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date" 
                         class="form-control"
                         v-model="form.date_etablissement_cautionnement_definitif"/>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Date d'expiration du cautionnement définitif</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select
                    :options="[
                      {value:'date',libelle:'Date'},
                      {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                      {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                      {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                    ]"
                    :reduce="expiration => expiration.value"
                    label="libelle"
                    ref="expiration_definitif"
                    id="expiration_definitif"
                    v-model="expiration_definitif"
                    @change="checkExpiration('expiration_cautionnement_definitif_texte','expiration_definitif')"
                    placeholder="Choisir un type d'expiration"
                  >
                    <span slot="no-options"
                          @click="$refs.programme.open = false"
                          class="text-danger">
                      Aucune type
                    </span>
                  </v-select>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-if="expiration_definitif=='date'"
                         v-model="form.expiration_cautionnement_definitif_date" />
                </div>
              </div>
                
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label class="req">Référence Facture</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('reference_facture')"
                         v-model="form.reference_facture" />
                  <span v-if="form.errors().has('reference_facture') && affiche.reference_facture !== 'reference_facture'"
                        v-text="form.errors().get('reference_facture')"
                        class="errorMsg"> </span>
                </div>
              </div>
                
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement  du certificat de participation</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_certif_participation')"
                         v-model="form.date_etablissement_certif_participation" />
                  <span v-if="form.errors().has('date_etablissement_certif_participation') && affiche.date_etablissement_certif_participation !== 'date_etablissement_certif_participation'"
                        v-text="form.errors().get('date_etablissement_certif_participation')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date d'établissement du procès verbal de reception des travaux</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_etablissement_proces_verbal_reception_travaux')"
                         v-model="form.date_etablissement_proces_verbal_reception_travaux" />
                  <span v-if="form.errors().has('date_etablissement_proces_verbal_reception_travaux') && affiche.date_etablissement_proces_verbal_reception_travaux !== 'date_etablissement_proces_verbal_reception_travaux'"
                        v-text="form.errors().get('date_etablissement_proces_verbal_reception_travaux')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label class="req">Date d'établissement du décompte</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input  type="date"
                          class="form-control"
                          v-on:click="removeSpanError('date_etablissement_decompte')"
                          v-model="form.date_etablissement_decompte" />
                  <span v-if="form.errors().has('date_etablissement_decompte') && affiche.date_etablissement_decompte !== 'date_etablissement_decompte'"
                        v-text="form.errors().get('date_etablissement_decompte')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Date de l'Attachement/constat des travaux</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_attachement_constat_travaux')"
                         v-model="form.date_attachement_constat_travaux" />
                  <span v-if="form.errors().has('date_attachement_constat_travaux') && affiche.date_attachement_constat_travaux !== 'date_attachement_constat_travaux'"
                        v-text="form.errors().get('date_attachement_constat_travaux')"
                        class="errorMsg"> </span>
                </div>
              </div>
            </div>
          </div>
        </table>
        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingFour">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour">
              <th>Rapports</th>
            </tr>
          </thead>
          <div id="collapseFour"
               class="collapse"
               aria-labelledby="headingFour"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="col-md-12"
                   v-for="(item, index) in rapports"
                   :key="index">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="">Rapport n°{{ index }}</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ item.rapport }}
                      </label>
                      <input
                        type="file"
                        :name="'rapport'+index"
                        class="custom-file-input"
                        :id="'rapport'+index"
                        :ref="'rapport'+index"
                        v-on:change="handleRapportUpload(index)"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                        :disabled="disabled"
                      />
                      <span v-if="form.errors().has('ordre_service_prorogation_delai') && affiche.ordre_service_prorogation_delai !== 'ordre_service_prorogation_delai'"
                            v-text="form.errors().get('ordre_service_prorogation_delai')"
                            class="errorMsg"> </span><br />
                      <span class="listExtension"> Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span class="errorMsg"> </span>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.ordre_service_prorogation_delai" />
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Date du rapport
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control"
                           v-model="form.rapports[index].date_rapport"
                           :name="`data[${index}]date_rapport`"/>
                  </div>
                </div> 
              </div>
              <div class="row mb-3">
                <button type="button"
                        class="btn btn-f-blue"
                        v-on:click="rapports.push({rapport:'',date_rapport:''}),form.rapports.push({rapport:'',date_rapport:''})">+</button>
                <button type="button" 
                        class="btn btn-f-blue"
                        v-on:click="rapports.splice(rapports.length-1, 1),form.rapports.splice(form.rapports.length-1, 1)">-</button>
              </div>
                
            </div>
          </div>
        </table> 
        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingThree">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree">
              <th>Fichiers à télécharger</th>
            </tr>
          </thead>
          <div id="collapseThree"
               class="collapse"
               aria-labelledby="headingThree"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Courrier d’indication de la ligne de la convention supportant la dépense
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.courrier_indication_ligne_lcd }}
                    </label>
                    <input
                      type="file"
                      name="courrier_indication_ligne_lcd"
                      class="custom-file-input"
                      id="courrier_indication_ligne_lcd"
                      ref="courrier_indication_ligne_lcd"
                      v-on:click="removeSpanError('courrier_indication_ligne_lcd')"
                      v-on:change="handleFileUpload('courrier_indication_ligne_lcd')"/>
                    <span
                      v-if="form.errors().has('courrier_indication_ligne_lcd') && affiche.courrier_indication_ligne_lcd !== 'courrier_indication_ligne_lcd'"
                      v-text="form.errors().get('courrier_indication_ligne_lcd')"
                      class="errorMsg">
                    </span>
                    <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.courrier_indication_ligne_lcd" />
                </div>
              </div>

              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Demande de proposition de prix</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.demande_proposition_prix }}</label>
                    <input
                      type="file"
                      name="demande_proposition_prix"
                      class="custom-file-input"
                      id="demande_proposition_prix"
                      ref="demande_proposition_prix"
                      v-on:click="removeSpanError('demande_proposition_prix')"
                      v-on:change="handleFileUpload('demande_proposition_prix')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"
                    />
                    <span
                      v-if="form.errors().has('demande_proposition_prix') && affiche.demande_proposition_prix !== 'demande_proposition_prix'"
                      v-text="form.errors().get('demande_proposition_prix')"
                      class="errorMsg"
                    >
                    </span>
                    <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.demande_proposition_prix" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Courrier de confirmation de prix</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.courrier_confirmation_prix }}</label>
                    <input
                      type="file"
                      name="courrier_confirmation_prix"
                      id="courrier_confirmation_prix"
                      ref="courrier_confirmation_prix"
                      class="custom-file-input"
                      v-on:click="removeSpanError('courrier_confirmation_prix')"
                      v-on:change="handleFileUpload('courrier_confirmation_prix')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"
                    />
                    <span
                      v-if="form.errors().has('courrier_confirmation_prix') && affiche.courrier_confirmation_prix !== 'courrier_confirmation_prix'"
                      v-text="form.errors().get('courrier_confirmation_prix')"
                      class="errorMsg"
                    >
                    </span>
                    <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.courrier_confirmation_prix" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Courrier d’invitation à une séance de négociation</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.courrier_invitation_sn }}</label>
                    <input
                      type="file"
                      name="courrier_invitation_sn"
                      id="courrier_invitation_sn"
                      ref="courrier_invitation_sn"
                      class="custom-file-input"
                      v-on:click="removeSpanError('courrier_invitation_sn')"
                      v-on:change="handleFileUpload('courrier_invitation_sn')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"
                    />
                    <span
                      v-if="form.errors().has('courrier_invitation_sn') && affiche.courrier_invitation_!=='courrier_invitation_sn'"
                      v-text="form.errors().get('courrier_invitation_sn')"
                      class="errorMsg"
                    >
                    </span>
                    <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.courrier_invitation_sn" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Bon de livraison</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.bon_livraison }}</label>
                    <input
                      type="file"
                      name="bon_livraison"
                      id="bon_livraison"
                      ref="bon_livraison"
                      class="custom-file-input"
                      v-on:click="removeSpanError('bon_livraison')"
                      v-on:change="handleFileUpload('bon_livraison')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"
                    />
                    <span v-if="form.errors().has('bon_livraison') && affiche.bon_livraison !== 'bon_livraison'"
                          v-text="form.errors().get('bon_livraison')"
                          class="errorMsg"> </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.bon_livraison" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Certificat de service fait</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.certificat_service_fait }}</label>
                    <input
                      type="file"
                      name="certificat_service_fait"
                      id="certificat_service_fait"
                      ref="certificat_service_fait"
                      class="custom-file-input"
                      v-on:click="removeSpanError('certificat_service_fait')"
                      v-on:change="handleFileUpload('certificat_service_fait')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"
                    />
                    <span
                      v-if="form.errors().has('certificat_service_fait') && affiche.certificat_service_fait !== 'certificat_service_fait'"
                      v-text="form.errors().get('certificat_service_fait')"
                      class="errorMsg"
                    >
                    </span>
                    <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.certificat_service_fait" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Facture<sup><span class="req-star">*</span></sup></label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.facture }}</label>
                    <input
                      type="file"
                      name="facture"
                      id="facture"
                      ref="facture"
                      class="custom-file-input"
                      v-on:click="removeSpanError('facture')"
                      v-on:change="handleFileUpload('facture')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"
                    />
                    <span v-if="form.errors().has('facture') && affiche.facture !== 'facture'"
                          v-text="form.errors().get('facture')"
                          class="errorMsg"> </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.facture" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Courrier d'appel de fonds</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.courrier_appel_fonds }}</label>
                    <input
                      type="file"
                      name="courrier_appel_fonds"
                      id="courrier_appel_fonds"
                      ref="courrier_appel_fonds"
                      class="custom-file-input"
                      v-on:click="removeSpanError('courrier_appel_fonds')"
                      v-on:change="handleFileUpload('courrier_appel_fonds')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"
                    />
                    <span v-if="form.errors().has('courrier_appel_fonds') && affiche.courrier_appel_fonds !== 'courrier_appel_fonds'"
                          v-text="form.errors().get('courrier_appel_fonds')"
                          class="errorMsg"> </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.courrier_appel_fonds" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Courrier indication ligne supportant dépense</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.courrier_indication_ligne_supportant_depense }}</label>
                    <input
                      type="file"
                      name="courrier_indication_ligne_supportant_depense"
                      id="courrier_indication_ligne_supportant_depense"
                      ref="courrier_indication_ligne_supportant_depense"
                      class="custom-file-input"
                      v-on:click="removeSpanError('courrier_indication_ligne_supportant_depense')"
                      v-on:change="handleFileUpload('courrier_indication_ligne_supportant_depense')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('courrier_indication_ligne_supportant_depense') && affiche.courrier_indication_ligne_supportant_depense !== 'courrier_indication_ligne_supportant_depense'"
                          v-text="form.errors().get('courrier_indication_ligne_supportant_depense')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.courrier_indication_ligne_supportant_depense" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Courrier de demande proposition de prix</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.courrier_demande_proposition_prix }}</label>
                    <input
                      type="file"
                      name="courrier_demande_proposition_prix"
                      id="courrier_demande_proposition_prix"
                      ref="courrier_demande_proposition_prix"
                      class="custom-file-input"
                      v-on:click="removeSpanError('courrier_demande_proposition_prix')"
                      v-on:change="handleFileUpload('courrier_demande_proposition_prix')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('courrier_demande_proposition_prix') && affiche.courrier_demande_proposition_prix !== 'courrier_demande_proposition_prix'"
                          v-text="form.errors().get('courrier_demande_proposition_prix')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.courrier_demande_proposition_prix" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Courrier d’invitation à une séance de négociation</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.courrier_invitation_seance_negociation }}</label>
                    <input
                      type="file"
                      name="courrier_invitation_seance_negociation"
                      id="courrier_invitation_seance_negociation"
                      ref="courrier_invitation_seance_negociation"
                      class="custom-file-input"
                      v-on:click="removeSpanError('courrier_invitation_seance_negociation')"
                      v-on:change="handleFileUpload('courrier_invitation_seance_negociation')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('courrier_invitation_seance_negociation') && affiche.courrier_invitation_seance_negociation !== 'courrier_demande_proposition_prix'"
                          v-text="form.errors().get('courrier_invitation_seance_negociation')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.courrier_invitation_seance_negociation" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Courrier de confirmation de prix</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.courrier_confirmation_prix }}</label>
                    <input
                      type="file"
                      name="courrier_confirmation_prix"
                      id="courrier_confirmation_prix"
                      ref="courrier_confirmation_prix"
                      class="custom-file-input"
                      v-on:click="removeSpanError('courrier_confirmation_prix')"
                      v-on:change="handleFileUpload('courrier_confirmation_prix')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('courrier_confirmation_prix') && affiche.courrier_confirmation_prix !== 'courrier_confirmation_prix'"
                          v-text="form.errors().get('courrier_confirmation_prix')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.courrier_confirmation_prix" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Formulaire de demande de cotation</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.formulaire_demande_cotation }}</label>
                    <input
                      type="file"
                      name="formulaire_demande_cotation"
                      id="formulaire_demande_cotation"
                      ref="formulaire_demande_cotation"
                      class="custom-file-input"
                      v-on:click="removeSpanError('formulaire_demande_cotation')"
                      v-on:change="handleFileUpload('formulaire_demande_cotation')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('formulaire_demande_cotation') && affiche.formulaire_demande_cotation !== 'formulaire_demande_cotation'"
                          v-text="form.errors().get('formulaire_demande_cotation')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.formulaire_demande_cotation" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Formulaire de sélection</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.formulaire_selection }}</label>
                    <input
                      type="file"
                      name="formulaire_selection"
                      id="formulaire_selection"
                      ref="formulaire_selection"
                      class="custom-file-input"
                      v-on:click="removeSpanError('formulaire_selection')"
                      v-on:change="handleFileUpload('formulaire_selection')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('formulaire_selection') && affiche.formulaire_selection !== 'formulaire_selection'"
                          v-text="form.errors().get('formulaire_selection')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.formulaire_selection" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Certificat pour paiement</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.certificat_paiement }}</label>
                    <input
                      type="file"
                      name="certificat_paiement"
                      id="certificat_paiement"
                      ref="certificat_paiement"
                      class="custom-file-input"
                      v-on:click="removeSpanError('certificat_paiement')"
                      v-on:change="handleFileUpload('certificat_paiement')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('certificat_paiement') && affiche.certificat_paiement !== 'certificat_paiement'"
                          v-text="form.errors().get('certificat_paiement')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.certificat_paiement" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Certificat pour paiement</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.certificat_paiement }}</label>
                    <input
                      type="file"
                      name="certificat_paiement"
                      id="certificat_paiement"
                      ref="certificat_paiement"
                      class="custom-file-input"
                      v-on:click="removeSpanError('certificat_paiement')"
                      v-on:change="handleFileUpload('certificat_paiement')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('certificat_paiement') && affiche.certificat_paiement !== 'certificat_paiement'"
                          v-text="form.errors().get('certificat_paiement')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.certificat_paiement" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Tableau récapitulatif dépenses effectuées demande précédente</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.tableau_recap_depense_precedente }}</label>
                    <input
                      type="file"
                      name="tableau_recap_depense_precedente"
                      id="tableau_recap_depense_precedente"
                      ref="tableau_recap_depense_precedente"
                      class="custom-file-input"
                      v-on:click="removeSpanError('tableau_recap_depense_precedente')"
                      v-on:change="handleFileUpload('tableau_recap_depense_precedente')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('tableau_recap_depense_precedente') && affiche.tableau_recap_depense_precedente !== 'tableau_recap_depense_precedente'"
                          v-text="form.errors().get('tableau_recap_depense_precedente')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.tableau_recap_depense_precedente" />
                  </div>
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Ordre de mission</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.ordre_mission }}</label>
                    <input
                      type="file"
                      name="ordre_mission"
                      id="ordre_mission"
                      ref="ordre_mission"
                      class="custom-file-input"
                      v-on:click="removeSpanError('ordre_mission')"
                      v-on:change="handleFileUpload('ordre_mission')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('ordre_mission') && affiche.ordre_mission !== 'ordre_mission'"
                          v-text="form.errors().get('ordre_mission')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.tableau_recap_depense_precedente" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Pièces justificatives des dépenses effectuées sur les appels de fonds précedent</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.pieces_justif_depenses_appels_fonds_precedent }}</label>
                    <input
                      type="file"
                      name="pieces_justif_depenses_appels_fonds_precedent"
                      id="pieces_justif_depenses_appels_fonds_precedent"
                      ref="pieces_justif_depenses_appels_fonds_precedent"
                      class="custom-file-input"
                      v-on:click="removeSpanError('pieces_justif_depenses_appels_fonds_precedent')"
                      v-on:change="handleFileUpload('pieces_justif_depenses_appels_fonds_precedent')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('pieces_justif_depenses_appels_fonds_precedent') && affiche.pieces_justif_depenses_appels_fonds_precedent !== 'pieces_justif_depenses_appels_fonds_precedent'"
                          v-text="form.errors().get('pieces_justif_depenses_appels_fonds_precedent')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.pieces_justif_depenses_appels_fonds_precedent" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Lettre d’invitation à la formation</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.lettre_invitation_formation }}</label>
                    <input
                      type="file"
                      name="lettre_invitation_formation"
                      id="lettre_invitation_formation"
                      ref="lettre_invitation_formation"
                      class="custom-file-input"
                      v-on:click="removeSpanError('lettre_invitation_formation')"
                      v-on:change="handleFileUpload('lettre_invitation_formation')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('lettre_invitation_formation') && affiche.lettre_invitation_formation !== 'lettre_invitation_formation'"
                          v-text="form.errors().get('lettre_invitation_formation')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.lettre_invitation_formation" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Pièces justificatives dépenses engagées pendant la formation </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.pieces_justificatives_depenses_engagees_pendant_formation }}</label>
                    <input
                      type="file"
                      name="pieces_justificatives_depenses_engagees_pendant_formation"
                      id="pieces_justificatives_depenses_engagees_pendant_formation"
                      ref="pieces_justificatives_depenses_engagees_pendant_formation"
                      class="custom-file-input"
                      v-on:click="removeSpanError('pieces_justificatives_depenses_engagees_pendant_formation')"
                      v-on:change="handleFileUpload('pieces_justificatives_depenses_engagees_pendant_formation')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('pieces_justificatives_depenses_engagees_pendant_formation') && affiche.pieces_justificatives_depenses_engagees_pendant_formation !== 'pieces_justificatives_depenses_engagees_pendant_formation'"
                          v-text="form.errors().get('pieces_justificatives_depenses_engagees_pendant_formation')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.pieces_justificatives_depenses_engagees_pendant_formation" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Certificat de participation à la formation/séminaire/atélier/mission</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.certificat_participation_formation_seminaire_atelier_mission }}</label>
                    <input
                      type="file"
                      name="certificat_participation_formation_seminaire_atelier_mission"
                      id="certificat_participation_formation_seminaire_atelier_mission"
                      ref="certificat_participation_formation_seminaire_atelier_mission"
                      class="custom-file-input"
                      v-on:click="removeSpanError('certificat_participation_formation_seminaire_atelier_mission')"
                      v-on:change="handleFileUpload('certificat_participation_formation_seminaire_atelier_mission')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('certificat_participation_formation_seminaire_atelier_mission') && affiche.certificat_participation_formation_seminaire_atelier_mission !== 'certificat_participation_formation_seminaire_atelier_mission'"
                          v-text="form.errors().get('certificat_participation_formation_seminaire_atelier_mission')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.certificat_participation_formation_seminaire_atelier_mission" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Cautionnement d'Avance de Démarrage </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.cautionnement_avance_demarrage }}</label>
                    <input
                      type="file"
                      name="cautionnement_avance_demarrage"
                      id="cautionnement_avance_demarrage"
                      ref="cautionnement_avance_demarrage"
                      class="custom-file-input"
                      v-on:click="removeSpanError('cautionnement_avance_demarrage')"
                      v-on:change="handleFileUpload('cautionnement_avance_demarrage')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('cautionnement_avance_demarrage') && affiche.cautionnement_avance_demarrage !== 'cautionnement_avance_demarrage'"
                          v-text="form.errors().get('cautionnement_avance_demarrage')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.cautionnement_avance_demarrage" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Retenue de Garantie</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.retenue_garantie }}</label>
                    <input
                      type="file"
                      name="retenue_garantie"
                      id="retenue_garantie"
                      ref="retenue_garantie"
                      class="custom-file-input"
                      v-on:click="removeSpanError('retenue_garantie')"
                      v-on:change="handleFileUpload('retenue_garantie')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('retenue_garantie') && affiche.retenue_garantie !== 'retenue_garantie'"
                          v-text="form.errors().get('retenue_garantie')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.retenue_garantie" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Cautionnement définitif</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.caution_definitif }}</label>
                    <input
                      type="file"
                      name="caution_definitif"
                      id="caution_definitif"
                      ref="caution_definitif"
                      class="custom-file-input"
                      v-on:click="removeSpanError('caution_definitif')"
                      v-on:change="handleFileUpload('caution_definitif')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('caution_definitif') && affiche.caution_definitif !== 'caution_definitif'"
                          v-text="form.errors().get('caution_definitif')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.caution_definitif" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Proces verbal de reception des travaux</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.proces_verbal_reception_travaux }}</label>
                    <input
                      type="file"
                      name="proces_verbal_reception_travaux"
                      id="proces_verbal_reception_travaux"
                      ref="proces_verbal_reception_travaux"
                      class="custom-file-input"
                      v-on:click="removeSpanError('proces_verbal_reception_travaux')"
                      v-on:change="handleFileUpload('proces_verbal_reception_travaux')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('proces_verbal_reception_travaux') && affiche.proces_verbal_reception_travaux !== 'proces_verbal_reception_travaux'"
                          v-text="form.errors().get('proces_verbal_reception_travaux')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.proces_verbal_reception_travaux" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Décompte<sup><span class="req-star">*</span></sup></label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.decompte }}</label>
                    <input
                      type="file"
                      name="decompte"
                      id="decompte"
                      ref="decompte"
                      class="custom-file-input"
                      v-on:click="removeSpanError('decompte')"
                      v-on:change="handleFileUpload('decompte')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('decompte') && affiche.decompte !== 'decompte'"
                          v-text="form.errors().get('decompte')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.decompte" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label for="">Attachement y compris constat des travaux </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <div class="custom-file">
                    <label class="custom-file-label">{{ inputFiles.attachement_y_compris_travaux }}</label>
                    <input
                      type="file"
                      name="attachement_y_compris_travaux"
                      id="attachement_y_compris_travaux"
                      ref="attachement_y_compris_travaux"
                      class="custom-file-input"
                      v-on:click="removeSpanError('attachement_y_compris_travaux')"
                      v-on:change="handleFileUpload('attachement_y_compris_travaux')"
                      accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                      :disabled="disabled"/>
                    <span v-if="form.errors().has('attachement_y_compris_travaux') && affiche.attachement_y_compris_travaux !== 'attachement_y_compris_travaux'"
                          v-text="form.errors().get('attachement_y_compris_travaux')"
                          class="errorMsg"> 
                    </span> <br />
                    <span class="listExtension"> Format :
                      <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading.attachement_y_compris_travaux" />
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Observations</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <textarea class="form-control"
                            rows="3"
                            v-on:click="removeSpanError('observation')"
                            v-model="form.observation"> </textarea>
                  <span v-if="form.errors().has('observation') && affiche.observation !== 'observation'"
                        v-text="form.errors().get('observation')"
                        class="errorMsg"> </span>
                </div>
              </div>
            </div>
          </div>
        </table>
        <div class="form-row row mt-4">
          <div class="col-md-8 offset-sm-5">
            <div class="form-group">
              <div class="button_form">
                <button type="button"
                        class="btn btn-f-blue enter_btn"
                        @click="submit">
                  <i class="flaticon flaticon-folder"></i>
                  Envoyer
                </button>
                <button type="reset"
                        class="btn btn-f-blue cancle_btn"
                        data-toggle="modal"
                        data-target="#exampleModal">
                  <i class="flaticon flaticon-cancel"></i>
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- LE MODAL  -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-uppercase"
                id="exampleModalLabel">justifiez la demande</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <textarea class="form-control"
                          name="demande_info"
                          id=""
                          placeholder="Votre justification"
                          rows="3"
                          v-model="commentaire.text"> </textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    @click="EnvoyerLeCommentaire()"
                    class="btn btn-primary">Envoyer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./decompte.css";
.upload_input::after {
  left: 0;
  width: 88px;
  content: "Fichier..." !important;
  font-size: 16px;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  line-height: 30px;
  cursor: pointer;
} 
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
form button.btn-primary,
form button.btn-secondary,
form button.btn-f-blue,
span[slot="no-options"].text-danger{
  font-size: 0.8em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: fit-content;
  }
}
</style>
<script>
import form from "vuejs-form"
import Loader from "@/components/shared/Spinner"
import Notif from "@/components/shared/Toast"
import utils from "@/assets/js/utils"
import VSelect from "vue-select"
import 'vue-select/dist/vue-select.css'
import { mapActions, mapGetters, mapMutations } from "vuex"
export default {
  name: "CreateDecompte",
  components: {
    Loader,
    Notif,
    VSelect
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    rapports:[{rapport:"",date_rapport:""}],

    montant_ordonnancement: "",
    montant_facture_decompte: "",
    montant_decompte:"",
    expiration_ad:"date",
    expiration_rg:"date",
    expiration_definitif:"date",
    form: form({
      marche: "",
      ordonnancement:"",
      numero_decompte: "",
      // sous_traitant: "",
      
      type_decompte: "decompte_travaux_dgir",
      courrier_indication_ligne_lcd: "",
      demande_proposition_prix: "",
      courrier_invitation_sn: "",
      courrier_confirmation_prix: "",
      bon_livraison: "",
      certificat_service_fait: "",
      situation_financiere_marche: "",
      situation_financiere_ordonnancement: "",
      facture: "",
      montant_facture_decompte: "",
      reference_facture: "",
      date_facture: "",
      observation: "",
      date_etablissement_courrier_appel_fonds:"",
      reference_courrier_appel_fonds:"",
      date_etablissement_courrier_indication_ligne_supportant_depense:"",
      reference_courrier_indication_ligne_supportant_depense:"",
      reference_courrier_demande_proposition_prix:"",
      date_etablissement_courrier_demande_proposition_prix:"",
      date_etablissement_courrier_invitation_seance_negociation:"",
      reference_courrier_invitation_seance_negociation:"",
      date_etablissement_courrier_confirmation_prix:"",
      reference_courrier_confirmation_prix:"",
      date_etablissement_courrier_demande_cotation:"",
      reference_demande_cotation:"",
      date_etablissement_formulaire_selection:"",
      reference_formulaire_selection:"",
      date_etablissement_certificat_service_fait:"",
      reference_certificat_service_fait:"",
      date_etablissement_certificat_pour_paiement:"",
      reference_certificat_paiement:"",
      date_etablissement_bon_livraison:"",
      date_etablissement_ordre_mission:"",
      reference_ordre_mission:"",
      date_etablissement_lettre_invitation_formation:"",
      reference_lettre_invitation:"",
      expiration_caution_ad_date: "",
      expiration_caution_ad_texte: "",
      expiration_caution_rg_date: "",
      expiration_caution_rg_texte: "",
      expiration_cautionnement_definitif_date: "",
      expiration_cautionnement_definitif_texte: "",
      date_etablissement_certif_participation:"",
      date_etablissement_cautionnement_ad:"",
      date_etablissement_cautionnement_rg:"",
      date_etablissement_cautionnement_definitif:"",
      date_etablissement_proces_verbal_reception_travaux:"",
      date_etablissement_decompte:"",
      date_attachement_constat_travaux:"",
      courrier_appel_fonds:"",
      courrier_indication_ligne_supportant_depense:"",
      courrier_demande_proposition_prix:"",
      formulaire_demande_cotation:"",
      formulaire_selection: "",
      certificat_paiement:"",
      rapport_activite:"",
      tableau_recap_depense_precedente:"",
      ordre_mission: "",
      pieces_justif_depenses_appels_fonds_precedent: "",
      lettre_invitation_formation: "",
      pieces_justificatives_depenses_engagees_pendant_formation: "",
      certificat_participation_formation_seminaire_atelier_mission: "",
      cautionnement_avance_demarrage: "",
      retenue_garantie: "",
      caution_definitif: "",
      proces_verbal_reception_travaux: "",
      attachement_y_compris_travaux: "",
      rapports:[{rapport:"",date_rapport:""}],
    }),
    gmarche: "",
    listExtensionFiles: [],
    marcheData: {
      limite: 100,
      avant: "MQ=="
    },
    //Gestion de l'envoi du commentaire
    viewButtonModal: false,
    commentaireEnvoyer: false,
    commentaire: {
      text: ""
    },
    uploadfile: "courrier_indication_ligne_lcd",
    disabled: false,
    loading: {
      courrier_indication_ligne_lcd: false,
      demande_proposition_prix: false,
      courrier_invitation_sn: false,
      courrier_confirmation_prix: false,
      bon_livraison: false,
      certificat_service_fait: false,
      situation_financiere_marche: false,
      facture: false,
      decompte: false,
      attachement: false,
      rapport: false,
      // courrier_appel_fonds: false,
      // courrier_indication_ligne_supportant_depense: false,
      // courrier_demande_proposition_prix: false,
      // formulaire_demande_cotation: false,
      // formulaire_selection: false,
      // certificat_paiement: false,
      // rapport_activite: false,
      // tableau_recap_depense_precedente: false,
      // ordre_mission: false,
      // pieces_justif_depenses_appels_fonds_precedent: false,
      // lettre_invitation_formation: false,
      // piece_justif_depenses_eng_pdn_form: false,
      // certificat_participation_form_seminaire: false,
      // cautionnement_avance_demarrage: false,
      // cautionnement_retenue_garantie_RG: false,
      // caution_definitif: false,
      // proces_verbal_reception_travaux: false,
      // attachement_y_compris_travaux: false,
      // ordre_service_demarrage: false,
      // ordre_service_arret: false,
      // ordre_service_reprise_travaux: false,
      // ordre_service_prorogation_delai: false,
      // ordre_service_modification_itineraire: false,
      // rapport_activite_mensuel_trim_finanal: false,
    },
    inputFiles: {
      courrier_indication_ligne_lcd: "",
      demande_proposition_prix: "",
      courrier_invitation_sn: "",
      courrier_confirmation_prix: "",
      bon_livraison: "",
      certificat_service_fait: "",
      situation_financiere_marche: "",
      facture: "",
      decompte:"",
      attachement:"",
      rapport:"",
      // courrier_appel_fonds: "",
      // courrier_indication_ligne_supportant_depense: "",
      // courrier_demande_proposition_prix: "",
      // formulaire_demande_cotation: "",
      // formulaire_selection: "",
      // certificat_paiement: "",
      // rapport_activite: "",
      // tableau_recap_depense_precedente: "",
      // ordre_mission: "",
      // pieces_justif_depenses_appels_fonds_precedent: "",
      // lettre_invitation_formation: "",
      // piece_justif_depenses_eng_pdn_form: "",
      // certificat_participation_form_seminaire: "",
      // cautionnement_avance_demarrage:"",
      // cautionnement_retenue_garantie_RG:"",
      // caution_definitif: "",
      // proces_verbal_reception_travaux: "",
      // attachement_y_compris_travaux: "",
      // ordre_service_demarrage: "",
      // ordre_service_arret: "",
      // ordre_service_reprise_travaux: "",
      // ordre_service_prorogation_delai: "",
      // ordre_service_modification_itineraire: "",
      // rapport_activite_mensuel_trim_finanal: "",
      
    },
    date_debut: "date_debut_execution",
    date_fin: "date_fin_execution"
  }),
  watch: {
    assignDecompte() {
      if (this.assignDecompte) {
        const assigndec = this.assignDecompte
        this.form.id = assigndec.id
        this.form.marche = assigndec.marche.id
        this.form.numero_decompte = assigndec.numero_decompte
        // this.form.sous_traitant = assigndec.sous_traitant.id
        this.form.type_entretien = assigndec.type_entretien
        // decompte travaux
        this.form.reference_lettre_commande = assigndec.reference_lettre_commande
        this.form.date_lettre_commande = assigndec.date_lettre_commande
        this.form.date_ordre_service = assigndec.date_ordre_service
        this.form.date_demarrage_effectif = assigndec.date_demarrage_effectif
        // this.form.numero_lettre_commande = assigndec.numero_lettre_commande
        // this.form.date_etablissement_lettre_commande = assigndec.date_etablissement_lettre_commande
        // this.form.date_etablissement_courrier_appel_fond = assigndec.date_etablissement_courrier_appel_fond
        // this.form.reference_courrier_appel_fond = assigndec.reference_courrier_appel_fond
        // this.form.date_etablissement_courrier_indication_ligne_supp_depense = assigndec.date_etablissement_courrier_indication_ligne_supp_depense
        // this.form.reference_courrier_indication_ligne_supportant_depense = assigndec.reference_courrier_indication_ligne_supportant_depense
        // this.form.reference_courrier_demande_proposition_prix = assigndec.reference_courrier_demande_proposition_prix
        // this.form.date_etablissement_demande_proposition_prix = assigndec.date_etablissement_demande_proposition_prix
        // this.form.date_etablissement_courrier_negociation = assigndec.date_etablissement_courrier_negociation
        // this.form.reference_courrier_invitation_negociation = assigndec.reference_courrier_invitation_negociation
        // this.form.date_etablissement_courrier_confirmation_prix = assigndec.date_etablissement_courrier_confirmation_prix
        // this.form.reference_confirmation_prix = assigndec.reference_confirmation_prix
        // this.form.date_etablissement_courrier_demande_cotation = assigndec.date_etablissement_courrier_demande_cotation
        // this.form.reference_demande_cotation = assigndec.reference_demande_cotation
        // this.form.date_etablissement_formulaire_selection = assigndec.date_etablissement_formulaire_selection
        // this.form.reference_formulaire_selection = assigndec.reference_formulaire_selection
        // this.form.date_etablissement_certicat_service_fait = assigndec.date_etablissement_certicat_service_fait
        // this.form.reference_certificat_fait = assigndec.reference_certificat_fait
        // this.form.date_etablissement_certicat_paiement = assigndec.date_etablissement_certicat_paiement
        // this.form.reference_certificat_paiment = assigndec.reference_certificat_paiment
        // this.form.date_etablissement_bon_livraison = assigndec.date_etablissement_bon_livraison
        // this.form.date_rapport = assigndec.date_rapport
        // this.form.date_etablissement_ordre_mission = assigndec.date_etablissement_ordre_mission
        // this.form.reference_ordre_mission = assigndec.reference_ordre_mission
        // this.form.date_etablissement_lettre_invitation_formation = assigndec.date_etablissement_lettre_invitation_formation
        // this.form.reference_lettre_invitation = assigndec.reference_lettre_invitation
        // this.form.expiration_caution_AD = assigndec.expiration_caution_AD
        // this.form.date_expiration_caution_AD = assigndec.date_expiration_caution_AD
        // this.form.expiration_caution_RG = assigndec.expiration_caution_RG
        // this.form.date_expiration_caution_RG = assigndec.date_expiration_caution_RG
        // this.form.expiration_cautionnement_definitif = assigndec.expiration_cautionnement_definitif
        // this.form.date_expiration_cautionnement_definitif = assigndec.date_expiration_cautionnement_definitif
        // this.form.date_etablissement_certif_participation = assigndec.date_etablissement_certif_participation
        // this.form.date_etablissement_cautionnement_AD = assigndec.date_etablissement_cautionnement_AD
        // this.form.date_etablissement_cautionnement_RG = assigndec.date_etablissement_cautionnement_RG
        // this.form.date_etablissement_cautionnement_definitif = assigndec.date_etablissement_cautionnement_definitif
        // this.form.date_etablissement_proces_verbal_reception_travaux = assigndec.date_etablissement_proces_verbal_reception_travaux
        // this.form.date_etablissement_decompte = assigndec.date_etablissement_decompte
        // this.form.date_attachement_constat_travaux = assigndec.date_attachement_constat_travaux
        // this.form.programme = assigndec.programme
        // this.form.rubrique = assigndec.rubrique
        // this.form.activite = assigndec.activite
        // this.form.departement = assigndec.departement
        // this.form.lot = assigndec.lot
        // this.form.entreprise = assigndec.entreprise
        // this.form.delai_execution_marche = assigndec.delai_execution_marche
        // this.form.reference_ordre_service_demarrage = assigndec.reference_ordre_service_demarrage
        // this.form.reference_ordre_service_arret = assigndec.reference_ordre_service_arret
        // this.form.reference_ordre_service_reprise_travaux = assigndec.reference_ordre_service_reprise_travaux
        // this.form.date_reprise_travaux_selon_ordre_service = assigndec.date_reprise_travaux_selon_ordre_service
        // this.form.reference_ordre_service_prorogation_delai = assigndec.reference_ordre_service_prorogation_delai
        // this.form.date_service_prorogation_delai = assigndec.date_service_prorogation_delai
        // this.form.reference_ordre_service_modification_itineraire = assigndec.reference_ordre_service_modification_itineraire
        // this.form.date_service_modification_itineraire = assigndec.date_service_modification_itineraire
        // this.form.montant_decompte = assigndec.montant_decompte
        // this.form.taux_execution_physique = assigndec.taux_execution_physique
        // this.form.date_fin_travaux = assigndec.date_fin_travaux
        // this.form.date_etablissement_feuille_temps = assigndec.date_etablissement_feuille_temps
        // this.form.date_etablissement_certificat_service_fait = assigndec.date_etablissement_certificat_service_fait
        // this.form.date_etablissement_rapport = assigndec.date_etablissement_rapport
        // this.form.ordre_service_demarrage = assigndec.ordre_service_demarrage
        // this.form.ordre_service_arret = assigndec.ordre_service_arret
        // this.form.ordre_service_reprise_travaux = assigndec.ordre_service_reprise_travaux
        // this.form.ordre_service_prorogation_delai = assigndec.ordre_service_prorogation_delai
        // this.form.ordre_service_modification_itineraire = assigndec.ordre_service_modification_itineraire
        // this.form.rapport_activite_mensuel_trim_finanal = assigndec.rapport_activite_mensuel_trim_finanal
       
          
        if (assigndec.courrier_appel_fonds) {
          this.form.courrier_appel_fonds = assigndec.courrier_appel_fonds
          this.getFileName("courrier_appel_fonds")
        }
        if (assigndec.courrier_indication_ligne_supportant_depense) {
          this.form.courrier_indication_ligne_supportant_depense = assigndec.courrier_indication_ligne_supportant_depense
          this.getFileName("courrier_indication_ligne_supportant_depense")
        }
        if (assigndec.courrier_demande_proposition_prix) {
          this.form.courrier_demande_proposition_prix = assigndec.courrier_demande_proposition_prix
          this.getFileName("courrier_demande_proposition_prix")
        }
        if (assigndec.formulaire_demande_cotation) {
          this.form.formulaire_demande_cotation = assigndec.formulaire_demande_cotation
          this.getFileName("formulaire_demande_cotation")
        }
        if (assigndec.formulaire_selection) {
          this.form.formulaire_selection = assigndec.formulaire_selection
          this.getFileName("formulaire_selection")
        }
        if (assigndec.certificat_paiement) {
          this.form.certificat_paiement = assigndec.certificat_paiement
          this.getFileName("certificat_paiement")
        }
        if (assigndec.rapport_activite) {
          this.form.rapport_activite = assigndec.rapport_activite
          this.getFileName("rapport_activite")
        }
        if (assigndec.tableau_recap_depense_precedente) {
          this.form.tableau_recap_depense_precedente = assigndec.tableau_recap_depense_precedente
          this.getFileName("tableau_recap_depense_precedente")
        }
        if (assigndec.ordre_mission) {
          this.form.ordre_mission = assigndec.ordre_mission
          this.getFileName("ordre_mission")
        }
        if (assigndec.pieces_justif_depenses_appels_fonds_precedent) {
          this.form.pieces_justif_depenses_appels_fonds_precedent = assigndec.pieces_justif_depenses_appels_fonds_precedent
          this.getFileName("pieces_justif_depenses_appels_fonds_precedent")
        }
        if (assigndec.lettre_invitation_formation) {
          this.form.lettre_invitation_formation = assigndec.lettre_invitation_formation
          this.getFileName("lettre_invitation_formation")
        }
        if (assigndec.piece_justif_depenses_eng_pdn_form) {
          this.form.piece_justif_depenses_eng_pdn_form = assigndec.piece_justif_depenses_eng_pdn_form
          this.getFileName("piece_justif_depenses_eng_pdn_form")
        }
        if (assigndec.certificat_participation_form_seminaire) {
          this.form.certificat_participation_form_seminaire = assigndec.certificat_participation_form_seminaire
          this.getFileName("certificat_participation_form_seminaire")
        }
        if (assigndec.cautionnement_avance_demarrage) {
          this.form.cautionnement_avance_demarrage = assigndec.cautionnement_avance_demarrage
          this.getFileName("cautionnement_avance_demarrage")
        }
        if (assigndec.cautionnement_retenue_garantie_RG) {
          this.form.cautionnement_retenue_garantie_RG = assigndec.cautionnement_retenue_garantie_RG
          this.getFileName("cautionnement_retenue_garantie_RG")
        }
        if (assigndec.caution_definitif) {
          this.form.caution_definitif = assigndec.caution_definitif
          this.getFileName("caution_definitif")
        }
        if (assigndec.proces_verbal_reception_travaux) {
          this.form.proces_verbal_reception_travaux = assigndec.proces_verbal_reception_travaux
          this.getFileName("proces_verbal_reception_travaux")
        }
        if (assigndec.attachement_y_compris_travaux) {
          this.form.attachement_y_compris_travaux = assigndec.attachement_y_compris_travaux
          this.getFileName("attachement_y_compris_travaux")
        }

        if (assigndec.courrier_indication_ligne_lcd) {
          this.form.courrier_indication_ligne_lcd = assigndec.courrier_indication_ligne_lcd
          this.getFileName("courrier_indication_ligne_lcd")
        }
        
        if (assigndec.demande_proposition_prix) {
          this.form.demande_proposition_prix = assigndec.demande_proposition_prix
          this.getFileName("demande_proposition_prix") 
        }
        
        if (assigndec.courrier_invitation_sn) {
          this.form.courrier_invitation_sn = assigndec.courrier_invitation_sn
          this.getFileName("courrier_invitation_sn")
        }

        if (assigndec.courrier_confirmation_prix) {
          this.form.courrier_confirmation_prix = assigndec.courrier_confirmation_prix
          this.getFileName("courrier_confirmation_prix")
        }
        
        if (assigndec.bon_livraison) {
          this.form.bon_livraison = assigndec.bon_livraison
          this.getFileName("bon_livraison")
        }
        
        if (assigndec.certificat_service_fait) {
          this.form.certificat_service_fait = assigndec.certificat_service_fait
          this.getFileName("certificat_service_fait")
        }

        if (assigndec.situation_financiere_marche) {
          this.form.situation_financiere_marche = assigndec.situation_financiere_marche
          this.getFileName("situation_financiere_marche")
        }
        
        if (assigndec.facture) {
          this.form.facture = assigndec.facture
          this.getFileName("facture")
        }
        
        this.form.montant_facture_decompte = assigndec.montant_facture_decompte
        this.montant_facture_decompte = assigndec.montant_facture_decompte.split(".")
        this.montant_facture_decompte = this.montant_facture_decompte[0]
        this.addSpace('montant_facture_decompte')
        this.form.reference_facture = assigndec.reference_facture
        this.form.date_facture = assigndec.date_facture
        this.form.observation = assigndec.observation
        // decompte presta intellectuelle
        this.form.type_decompte = assigndec.type_decompte
        this.form.objet_travaux = assigndec.objet_travaux
        this.form.numero_contrat = assigndec.numero_contrat
        // this.form.montant_total_contrat = assigndec.montant_total_contrat
        this.form.date_approbation = assigndec.date_approbation
        if (assigndec.montant_ordonnancement) {
          this.form.montant_ordonnancement = assigndec.montant_ordonnancement
          this.montant_ordonnancement = assigndec.montant_ordonnancement.split(".")
          this.montant_ordonnancement = this.montant_ordonnancement[0]
          this.addSpace('montant_ordonnancement')
        }
        
        this.form.date_ordonnancement = assigndec.date_ordonnancement
        this.form.date_debut_execution = assigndec.date_debut_execution
        this.form.date_fin_execution = assigndec.date_fin_execution
        
        if (assigndec.decompte) {
          this.form.decompte = assigndec.decompte        
          this.getFileName("decompte")
        }

        if(assigndec.rapport){
          this.form.rapport = assigndec.rapport
          this.getFileName("rapport")
        }

        if (assigndec.attachement) {
          this.form.attachement = assigndec.attachement
          this.getFileName("attachement")
        }
        // if (assigndec.ordre_service_demarrage) {
        //   this.form.ordre_service_demarrage = assigndec.ordre_service_demarrage
        //   this.getFileName("ordre_service_demarrage")
        // }
        // if (assigndec.ordre_service_arret) {
        //   this.form.ordre_service_arret = assigndec.ordre_service_arret
        //   this.getFileName("ordre_service_arret")
        // }
        // if (assigndec.ordre_service_reprise_travaux) {
        //   this.form.ordre_service_reprise_travaux = assigndec.ordre_service_reprise_travaux
        //   this.getFileName("ordre_service_reprise_travaux")
        // }
        // if (assigndec.rapport_activite_mensuel_trim_finanal) {
        //   this.form.rapport_activite_mensuel_trim_finanal = assigndec.rapport_activite_mensuel_trim_finanal
        //   this.getFileName("rapport_activite_mensuel_trim_finanal")
        // }
        
        this.form.reference_arc = assigndec.reference_arc
        this.form.reference_attestation_rc = assigndec.reference_attestation_rc_decompte
        this.form.reference_af = assigndec.reference_af
        this.form.reference_accord_financement = assigndec.reference_accord_financement_decompte
      }
    },
    situationMarche(){
      if (this.situationMarche) {
        this.form.situation_financiere_marche = Number(this.situationMarche.situation_financiere[0].marche)
        this.form.situation_financiere_ordonnancement = Number(this.situationMarche.situation_financiere[0].ordonnancement)
        console.log(this.situationMarche)
      }
    },
    singleDP() {
      if (this.singleDP) {
        const single = this.singleDP
        this.form.id = single.id
        this.form.numero_decompte = single.numero_decompte
        // this.form.sous_traitant = single.sous_traitant.id
        this.form.marche = single.marche.id
        this.form.partiel = true
        this.form.type_decompte = single.type_decompte
        if (single.type_entretien != "") {
          this.form.type_entretien = single.type_entretien
        }
        if (Math.round(Number(single.montant_facture_decompte)) != 0) {
          this.form.montant_facture_decompte = Math.round(Number(single.montant_facture_decompte))
          this.montant_facture_decompte = single.montant_facture_decompte.split(".")
          this.montant_facture_decompte = this.montant_facture_decompte[0]
          this.addSpace('montant_facture_decompte')
        }
        // decompte travaux
        // if (single.date_etablissement_lettre_commande != "0000-00-00") {
        //   this.form.date_etablissement_lettre_commande = single.date_etablissement_lettre_commande
        // }
        // if (single.date_etablissement_courrier_appel_fond != "0000-00-00") {
        //   this.form.date_etablissement_courrier_appel_fond = single.date_etablissement_courrier_appel_fond
        // }
        // if (single.date_etablissement_demande_proposition_prix != "0000-00-00") {
        //   this.form.date_etablissement_demande_proposition_prix = single.date_etablissement_demande_proposition_prix
        // }
        // if (single.date_etablissement_courrier_negociation != "0000-00-00") {
        //   this.form.date_etablissement_courrier_negociation = single.date_etablissement_courrier_negociation
        // }
        // if (single.date_etablissement_courrier_confirmation_prix != "0000-00-00") {
        //   this.form.date_etablissement_courrier_confirmation_prix = single.date_etablissement_courrier_confirmation_prix
        // }
        // if (single.date_etablissement_courrier_demande_cotation != "0000-00-00") {
        //   this.form.date_etablissement_courrier_demande_cotation = single.date_etablissement_courrier_demande_cotation
        // }
        // if (single.date_etablissement_formulaire_selection != "0000-00-00") {
        //   this.form.date_etablissement_formulaire_selection = single.date_etablissement_formulaire_selection
        // }
        // if (single.date_etablissement_certicat_service_fait != "0000-00-00") {
        //   this.form.date_etablissement_certicat_service_fait = single.date_etablissement_certicat_service_fait
        // }
        // if (single.date_etablissement_certicat_paiement != "0000-00-00") {
        //   this.form.date_etablissement_certicat_paiement = single.date_etablissement_certicat_paiement
        // }
        // if (single.date_etablissement_bon_livraison != "0000-00-00") {
        //   this.form.date_etablissement_bon_livraison = single.date_etablissement_bon_livraison
        // }
        // if (single.date_rapport != "0000-00-00") {
        //   this.form.date_rapport = single.date_rapport
        // }
        // if (single.date_etablissement_ordre_mission != "0000-00-00") {
        //   this.form.date_etablissement_ordre_mission = single.date_etablissement_ordre_mission
        // }
        // if (single.date_etablissement_lettre_invitation_formation != "0000-00-00") {
        //   this.form.date_etablissement_lettre_invitation_formation = single.date_etablissement_lettre_invitation_formation
        // }
        // if (single.date_expiration_caution_AD != "0000-00-00") {
        //   this.form.date_expiration_caution_AD = single.date_expiration_caution_AD
        // }
        // if (single.date_expiration_caution_RG != "0000-00-00") {
        //   this.form.date_expiration_caution_RG = single.date_expiration_caution_RG
        // }
        // if (single.date_expiration_cautionnement_definitif != "0000-00-00") {
        //   this.form.date_expiration_cautionnement_definitif = single.date_expiration_cautionnement_definitif
        // }
        // if (single.date_etablissement_certif_participation != "0000-00-00") {
        //   this.form.date_etablissement_certif_participation = single.date_etablissement_certif_participation
        // }
        // if (single.date_etablissement_cautionnement_AD != "0000-00-00") {
        //   this.form.date_etablissement_cautionnement_AD = single.date_etablissement_cautionnement_AD
        // }
        // if (single.date_etablissement_cautionnement_RG != "0000-00-00") {
        //   this.form.date_etablissement_cautionnement_RG = single.date_etablissement_cautionnement_RG
        // }
        // if (single.date_etablissement_cautionnement_definitif != "0000-00-00") {
        //   this.form.date_etablissement_cautionnement_definitif = single.date_etablissement_cautionnement_definitif
        // }
        // if (single.date_etablissement_proces_verbal_reception_travaux != "0000-00-00") {
        //   this.form.date_etablissement_proces_verbal_reception_travaux = single.date_etablissement_proces_verbal_reception_travaux
        // }
        // if (single.date_etablissement_decompte != "0000-00-00") {
        //   this.form.date_etablissement_decompte = single.date_etablissement_decompte
        // }
        // if (single.date_attachement_constat_travaux != "0000-00-00") {
        //   this.form.date_attachement_constat_travaux = single.date_attachement_constat_travaux
        // }

        if (single.date_lettre_commande != "0000-00-00") {
          this.form.date_lettre_commande = single.date_lettre_commande
        }
        if (single.courrier_confirmation_prix != "") {
          this.form.courrier_confirmation_prix = single.courrier_confirmation_prix
          this.getFileName("courrier_confirmation_prix")
        }
        if (single.bon_livraison != "") {
          this.form.bon_livraison = single.bon_livraison
          this.getFileName("bon_livraison")
        }
        if (single.date_ordre_service != "0000-00-00") {
          this.form.date_ordre_service = single.date_ordre_service
        }
        if (single.date_demarrage_effectif != "0000-00-00") {
          this.form.date_demarrage_effectif = single.date_demarrage_effectif
        }
        if (single.courrier_invitation_sn != "") {
          this.form.courrier_invitation_sn = single.courrier_invitation_sn
          this.getFileName("courrier_invitation_sn")
        }
        if (single.certificat_service_fait != "") {
          this.form.certificat_service_fait = single.certificat_service_fait
          this.getFileName("certificat_service_fait")
        }
        if (single.reference_lettre_commande != "") {
          this.form.reference_lettre_commande = single.reference_lettre_commande
        }
        if (single.situation_financiere_marche != "") {
          this.form.situation_financiere_marche = single.situation_financiere_marche
          this.getFileName("situation_financiere_marche")
        }
        if (single.courrier_indication_ligne_lcd != "") {
          this.form.courrier_indication_ligne_lcd = single.courrier_indication_ligne_lcd
          this.getFileName("courrier_indication_ligne_lcd")
        }
        if (single.reference_facture != "") {
          this.form.reference_facture = single.reference_facture
        }
        if (single.date_facture != "0000-00-00") {
          this.form.date_facture = single.date_facture
        }
        if (single.observation != "") {
          this.form.observation = single.observation
        }
        if (single.demande_proposition_prix != "") {
          this.form.demande_proposition_prix = single.demande_proposition_prix
          this.getFileName("demande_proposition_prix")
        }
        if (single.facture != "") {
          this.form.facture = single.facture
          this.getFileName("facture")
        }
        // decompte prestation intellectuelle
        if (single.objet_travaux != "") {
          this.form.objet_travaux = single.objet_travaux
        }
        if (single.numero_contrat != "") {
          this.form.numero_contrat = single.numero_contrat
        }
        // if (Math.round(Number(single.single.montant_total_contrat)) != 0) {
        //   this.form.montant_total_contrat = Math.round(Number(single.single.montant_total_contrat))
        // }
        
        // if (single.date_fin_travaux != "0000-00-00") {
        //   this.form.date_fin_travaux = single.date_fin_travaux
        // }
        // if (single.date_etablissement_feuille_temps != "0000-00-00") {
        //   this.form.date_etablissement_feuille_temps = single.date_etablissement_feuille_temps
        // }
        // if (single.date_etablissement_certificat_service_fait != "0000-00-00") {
        //   this.form.date_etablissement_certificat_service_fait = single.date_etablissement_certificat_service_fait
        // }
        // if (single.date_etablissement_rapport != "0000-00-00") {
        //   this.form.date_etablissement_rapport = single.date_etablissement_rapport
        // }
        // if (single.ordre_service_prorogation_delai != "0000-00-00") {
        //   this.form.ordre_service_prorogation_delai = single.ordre_service_prorogation_delai
        // }
        // if (single.ordre_service_modification_itineraire != "0000-00-00") {
        //   this.form.ordre_service_modification_itineraire = single.ordre_service_modification_itineraire
        // }


        if (single.date_approbation != "0000-00-00") {
          this.form.date_approbation = single.date_approbation
        }
        if (single.date_ordonnancement != "0000-00-00") {
          this.form.date_ordonnancement = single.date_ordonnancement
        }
        if (single.date_debut_execution != "0000-00-00") {
          this.form.date_debut_execution = single.date_debut_execution
        }
        if (single.date_fin_execution != "0000-00-00") {
          this.form.date_fin_execution = single.date_fin_execution
        }
        if (Math.round(Number(single.montant_ordonnancement)) != 0) {
          this.form.montant_ordonnancement = Math.round(Number(single.montant_ordonnancement))
          this.montant_ordonnancement = single.montant_ordonnancement.split(".")
          this.montant_ordonnancement = this.montant_ordonnancement[0]
          this.addSpace('montant_ordonnancement')
        }
        if (single.decompte != "") {
          this.form.decompte = single.decompte
          this.getFileName("decompte")
        }
        if (single.rapport != "") {
          this.form.rapport = single.rapport
          this.getFileName("rapport")
        }
        if (single.attachement != "") {
          this.form.attachement = single.attachement
          this.getFileName("attachement")
        }
        if (single.reference_arc != "") {
          this.form.reference_arc = single.reference_arc
        }
        if (single.reference_attestation_rc != "") {
          this.form.reference_attestation_rc = single.reference_attestation_rc_decompte
        }
        if (single.reference_af != "") {
          this.form.reference_af = single.reference_af
        }
        if (single.reference_accord_financement != "") {
          this.form.reference_accord_financement = single.reference_accord_financement_decompte
        }
      }
    },
    gmarche() {
      if (this.gmarche) {
        // if (this.type_user[1] == "AGEROUTE") {
        this.form.reference_attestation_rc = this.gmarche.reference_arc
        this.form.reference_accord_financement = this.gmarche.reference_af
        // } else {
        //   this.form.reference_arc = this.gmarche.reference_arc
        //   this.form.reference_af = this.gmarche.reference_af
        // }
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.loading[this.uploadfile] = false
        this.disabled = false
        // console.log(this.form[this.uploadfile])
      }
    },
    filerrors() {
      if (this.filerrors) {
        this.loading[this.uploadfile] = false
        this.notif.message = this.filerrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.disabled = false
            this.notif.activated = ""
            this.$refs[this.uploadfile]=""
            this.form[this.uploadfile]=""
            this.inputFiles[this.uploadfile]=""
            this.setFileErors("")
          }.bind(this),
          3000
        )
      }
    },
    updateDPmsg() {
      if (this.updateDPmsg) {
        this.notif.message = this.updateDPmsg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.updateDP("")
            // this.$router.push({ name: "decompte" })
            // location.reload()
          }.bind(this),
          3000
        )
      }
      if (this.dperrors) {
        this.notif.message = this.dperrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErrorsP("")
          }.bind(this),
          3000
        )
      }
    },
    successdecompte() {
      if (this.successdecompte) {
        this.notif.message = this.successdecompte
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            // this.$router.push({ name: "decompte" })
          }.bind(this),
          3000
        )
      }
      if (this.decompterrors) {
        this.notif.message = this.decompterrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErrors("")
          }.bind(this),
          3000
        )
      }
    },
    updateDecomptemsg() {
      if (this.updateDecomptemsg) {
        this.notif.message = this.updateDecomptemsg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.updateDecompte("")
            // this.$router.push({ name: "decompte" })
          }.bind(this),
          3000
        )
      }
      if (this.decerrors) {
        this.notif.message = this.decerrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErrors("")
          }.bind(this),
          3000
        )
      }
    }
  },
  created() {
    this.ListMarches()
    this.getSousTraitants()
    this.listExtensionFiles = utils.formaFichierAccepte
    if (this.$route.params.id) {
      if (this.$route.params.p) {
        this.getDecompteP(this.$route.params.id)
      } else {
        this.getDecompte(this.$route.params.id)
      }
    }
  },
  computed: {
    ...mapGetters(["decerrors", "successdecompte", "marches","marche", "files", "soustraitants", "assignDecompte", "updateDecomptemsg", "decompterrorsP", "situationMarche","singleDP", "updateDPmsg"])
  },
  methods: {
    ...mapActions(["saveDecomptes", "ListMarches", "saveFile", "getSousTraitants", "getDecompte", "updateDecomptes", "actionPostCommentaire", "getDecompteP", "updateDP","situationMarcheDecompte","getMarche"]),
    ...mapMutations(["updateDecompte", "setErrors","setFileErors", "updateDecompteP", "setErrorsP", "setSuccess","setSituationMarche"]),
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      if (!this.inputFiles[filename].includes(" ")) {
        const extension = this.inputFiles[filename].split(".")[1]
        if (this.listExtensionFiles.includes(extension)) {
          this.loading[filename] = true
          this.disabled = true
          this.uploadfile = filename
          this.saveFile(this.$refs[filename].files[0])
        } else {
          this.inputFiles[filename] = ""
          this.notif.message = "Mauvais format du fichier !!"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            5000
          )
        }
      }else{
        this.inputFiles[filename] = ""
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    handleRapportUpload(filename) {
      this.rapports[filename].rapport = this.$refs["rapport"+filename][0].files[0].name
      console.log(this.rapports[filename].rapport)
      if (!this.rapports[filename].rapport.includes(" ")) {
        const extension = this.rapports[filename].rapport.split(".")[1]
        if (this.listExtensionFiles.includes(extension)) {
          this.loading[filename] = true
          this.disabled = true
          this.uploadfile = filename
          this.saveFile(this.$refs["rapport"+filename][0].files[0])
        } else {
          this.rapports[filename].rapport = ""
          this.notif.message = "Mauvais format du fichier !!"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            5000
          )
        }
      }else{
        this.rapports[filename].rapport = ""
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    searchMarche(id) {
      this.getMarche(id)
      this.removeSpanError('marche')
    },
    searchSituation(id_marche,id_ordonnancement) {
      this.setSituationMarche("")
      this.situationMarcheDecompte({id_marche:id_marche,id_ordonnancement:id_ordonnancement})
    },
    getFileName(key){
      this.inputFiles[key] = this.form[key].split("/")
      this.inputFiles[key] = this.inputFiles[key][this.inputFiles[key].length-1]
      this[key] = this.inputFiles[key]
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
    getDate() {
      utils.getDate(this, this.date_debut, this.date_fin, "intellectuelle")
    },
    updateDecompteP() {
      this.recreate(this.form.montant_ordonnancement,'montant_ordonnnancement')
      this.recreate(this.form.montant_facture_decompte,'montant_facture_decompte')
      this.updateDP({ data: this.form.data, pid: this.form.data.id })
    },
    createDecompte() {
      // this.recreate(this.form.montant_ordonnancement,'montant_ordonnnancement')
      // this.recreate(this.form.montant_facture_decompte,'montant_facture_decompte')
      this.saveDecomptes(this.form.data)
    },
    recreate(value,key){
      var splinter = value.split(" ")
      this.form[key] = ""
      for (let index = 0; index < splinter.length; index++) {
        this.form[key] += splinter[index]
      }
    },
    submit() {
      this.recreate(this.form.montant_ordonnancement,'montant_ordonnnancement')
      this.recreate(this.form.montant_facture_decompte,'montant_facture_decompte')
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        if (this.assignDecompte.statut === "DEMANDE_INFORMATION") {
          this.form.statut = "EN_ATTENTE"
        }

        this.notif.message = "Votre choix a été pris en compte"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.updateDecomptes({ data: this.form.data, pid: this.form.data.id })
            // this.$router.push({ name: "decompte" })
            this.notif.activated = ""
            // location.reload()
          }.bind(this),
          1000
        )
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        this.form[key] = this[key]
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }

      this[key] = chaine
    },
    getMarche(id) {
      this.marches.donnees.forEach(marche => {
        if (marche.id == id) {
          this.gmarche = marche
        }
      })
    },
    soumettreFormulaire() {
      if (Number(this.form.montant_facture_decompte) <= this.form.situation_financiere_marche) {
        this.viewButtonModal = true
      }else{
        this.notif.message = "Le montant de la facture/decompte ne doit pas excéder la situation financière du marché"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    EnvoyerLeCommentaire() {
      this.commentaireEnvoyer = true
      this.viewButtonModal = false
      this.actionPostCommentaire({ data: this.commentaire, id: this.$route.params.id })
      this.submit()
    },
    goBack() {
      this.$router.push({ name: "DetailDecompte", params: { id: this.$route.params.id } })
    },
    checkExpiration(key,ekey){
      if (this[ekey]!="date") {
        this.form[key]=this[ekey]
      }else{
        this.form[key]=""
      }
      console.log(this.form[key])
    },
    CreateEspaceBetweenFileExtentions(){
      return utils.formaFichierAccepteToString()
    }
  }
}
</script>
